import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { SetTutorialStep } from "../../../store/actions/tutorial.actions";
import {
  TutorialSteps,
  TutorialTeacherSteps,
} from "../../enums/tutorial-steps";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { TutorialState } from "src/app/store/reducers/tutorial.reducer";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";

@Component({
  selector: "tutorial-text-box",
  templateUrl: "./tutorial-text-box.component.html",
  styleUrls: ["./tutorial-text-box.component.scss"],
})
export class TutorialTextboxComponent {
  @Input() customStyle: { [key: string]: string };
  // @Input() arrowPosition: "top" | "right" | "bottom" | "left" = "bottom";
  @Input() message: string;
  @Input() title: string;
  @Input() step: number;
  @Input() finishTutorial: boolean = false;

  isTeacher$: Observable<boolean> = this.store.select(
    (state) => state.account.member.is_teacher,
  );

  tutorial$: Observable<TutorialState> = this.store.select(
    (state) => state.tutorial,
  );

  TutorialSteps = TutorialSteps;
  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(
    private store: Store<{
      tutorial: TutorialState;
      account: fromAccountStore.AccountState;
    }>,
    private router: Router,
  ) {}

  next(id: string, teacher: boolean): void {
    this.store.dispatch(
      new SetTutorialStep({
        id,
        tutorial_skip: false,
        tutorial_step: this.step + 1,
      }),
    );

    if (this.step + 1 === TutorialTeacherSteps.GROUP_DASHBOARD) {
      this.router.navigate(["/teacher/groups"]);
    } else if (this.step + 1 === TutorialTeacherSteps.COURSES_LIST) {
      this.router.navigate(["/teacher/courses"]);
    } else if (this.step + 1 === TutorialTeacherSteps.COURSE_EXPAND_GROUPS) {
      this.step = this.step + 1;
    } else if (this.step + 1 === TutorialTeacherSteps.COURSES_BTN && !teacher) {
      this.store.dispatch(
        new SetTutorialStep({
          id,
          tutorial_skip: false,
          tutorial_step: 99,
        }),
      );
    }
  }

  close(id: string): void {
    this.store.dispatch(
      new SetTutorialStep({
        id,
        tutorial_skip: true,
        tutorial_step: 99,
      }),
    );
  }
}
