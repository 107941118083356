<ng-container
  *ngIf="{
    isToggled: isToggled$ | async,
    bulk: bulkInProgress$ | async
  } as exerciseState"
>
  <div
    class="exercise-container"
    [ngClass]="getAppearance(exerciseState.isToggled)"
    #exerciseBody
  >
    <div
      role="button"
      class="exercise-header"
      (click)="toggle(exerciseState.isToggled)"
    >
      <div class="row align-items-center">
        <div class="col-auto d-flex align-items-center">
          <exercise-status [status]="status | async" />
        </div>

        <div class="col-5 d-flex align-items-center">
          <span class="heading-6">{{ exercise.name }}</span>
        </div>

        <ng-container *ngIf="isOn && !isOnboarding">
          <div class="col-auto">
            <course-exercise-difficulty
              [difficulty]="exercise.difficulty"
              [class.exercise-individual]="isIndividualExercise"
            />
          </div>
        </ng-container>

        <div class="exercise-element-actions">
          <ng-container *ngIf="isTeacher && activeGroup">
            <switch-button-expanded
              *ngIf="exercise.quiz_properties?.can_toggle_solutions"
              tooltipText="COURSES.COURSE.EXERCISE.SELECT_UNSELECT_SOLUTIONS"
              set="uil"
              icon="history"
              theme="hsecondary"
              [toggled]="exercise.quiz_properties?.has_solutions"
              [busy]="isTogglingHistory"
              (clicked)="selectUnselectHistory($event)"
            >
              <switch-checkbox
                label="COURSES.COURSE.EXERCISE.SELECT_UNSELECT_SELECTED_ANSWERS"
                [name]="'selectUnselectSelectedAnswers-' + exercise.id"
                [toggled]="exercise.quiz_properties?.show_selected_answers"
                [busy]="isTogglingSelectedAnswers"
                (clicked)="selectUnselectSelectedAnswers()"
              />

              <switch-checkbox
                label="COURSES.COURSE.EXERCISE.SELECT_UNSELECT_CORRECT_ANSWERS"
                [name]="'selectUnselectCorrectAnswers' + exercise.id"
                [toggled]="exercise.quiz_properties?.show_correct_answers"
                [busy]="isTogglingCorrectAnswers"
                (clicked)="selectUnselectCorrectAnswers()"
              />
            </switch-button-expanded>

            <div
              [ngClass]="{
                'tutorial-icon':
                  (tutorial$ | async) ===
                    TutorialTeacherSteps.COURSE_SINGLE_BTN &&
                  isTeacher &&
                  exercise.available_help
              }"
              class="exercise-element-action"
            >
              <switch-button
                *ngIf="exercise.available_help"
                tooltip="COURSES.COURSE.EXERCISE.SELECT_UNSELECT_HELP"
                set="uil"
                icon="question-circle"
                theme="hsecondary"
                [toggled]="exercise.has_help"
                [busy]="
                  exerciseState.bulk.section === exercise.section &&
                  (isTogglingHelp || exerciseState.bulk.enabled_help)
                "
                (clicked)="selectUnselectHelp($event)"
              />
            </div>

            <div
              [ngClass]="{
                'tutorial-icon':
                  (tutorial$ | async) ===
                    TutorialTeacherSteps.COURSE_SINGLE_BTN &&
                  isTeacher &&
                  exercise.available_video
              }"
              class="exercise-element-action"
            >
              <switch-button
                *ngIf="exercise.available_video"
                tooltip="COURSES.COURSE.EXERCISE.SELECT_UNSELECT_VIDEO"
                set="uil"
                icon="video-question"
                theme="hsecondary"
                [toggled]="exercise.has_video"
                [busy]="
                  exerciseState.bulk.section === exercise.section &&
                  (isTogglingVideo || exerciseState.bulk.enabled_video)
                "
                (clicked)="selectUnselectVideo($event)"
              />
            </div>

            <div
              [ngClass]="{
                'tutorial-icon':
                  (tutorial$ | async) ===
                    TutorialTeacherSteps.COURSE_SINGLE_BTN && isTeacher
              }"
              class="exercise-element-action"
            >
              <switch-button
                tooltip="COURSES.COURSE.EXERCISE.SELECT_UNSELECT"
                icon="eye"
                theme="hsecondary"
                [toggled]="exercise.available"
                [busy]="
                  exerciseState.bulk.section === exercise.section &&
                  (isSelecting || exerciseState.bulk.selected)
                "
                (clicked)="selectUnselect($event)"
              />
            </div>
          </ng-container>

          <ng-container *ngIf="isOn">
            <div
              [ngClass]="{
                'tutorial-icon':
                  (tutorial$ | async) ===
                    TutorialTeacherSteps.COURSE_EXPAND_BTN && isTeacher
              }"
            >
              <span
                class="typcn typcn-chevron-right chevron-toggled"
                [ngClass]="{ toggled: exerciseState.isToggled }"
              ></span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="exercise-content" *ngIf="isOn && exerciseState.isToggled">
      <course-exercise-desc [content]="exercise.description || ''" />

      <div
        class="d-flex flex-row justify-content-between"
        *ngIf="!isOnboarding"
      >
        <div class="d-flex flex-row">
          <ng-container *ngIf="canShowExerciseAnswer">
            <course-exercise-answer
              [ngClass]="{
                'tutorial-icon':
                  (tutorial$ | async) ===
                    TutorialTeacherSteps.COURSE_START_BTN && isTeacher
              }"
              [exercise]="exercise"
              [courseId]="courseId"
              [activeGroup]="activeGroup"
              [topicId]="topicId"
              [emitters]="emitters"
              [teacher]="isTeacher"
            />
          </ng-container>
          <div class="course-exercise-help-buttons pr-3">
            <button
              class="theme-hprimarydark help-button"
              *ngIf="
                (isTeacher && exercise.available_video) ||
                (!isTeacher && exercise.has_video)
              "
              (click)="callVideoHelpClick()"
            >
              <span class="typcn typcn-video"></span>
              {{ "COURSES.COURSE.EXERCISE.VIDEO_HELP_BUTTON" | translate }}
            </button>
            <button
              class="theme-hprimarydark help-button"
              *ngIf="
                (isTeacher && exercise.available_help) ||
                (!isTeacher && exercise.has_help)
              "
              (click)="callHelpClick()"
            >
              <span class="typcn typcn-globe-outline"></span>
              {{ "COURSES.COURSE.EXERCISE.HELP_BUTTON" | translate }}
            </button>
          </div>
          <ng-container *ngIf="isAnswerTypeFileUpload && !isTeacher">
            <course-exercise-student-upload
              class="student-upload"
              [class.student-upload-has-video]="exercise.has_video"
              [file]="uploadedFile$ | async"
              [exercise]="exercise"
              (clearEvent)="handleClearEvent()"
              (uploadEvent)="handleUploadFileClick(exercise)"
              (downloadEvent)="handleDownload($event)"
            />
          </ng-container>
        </div>

        <div [ngSwitch]="true">
          <div class="d-flex justify-content-center justify-content-md-end">
            <ng-container *ngSwitchCase="canShowIDEControlButtons">
              <div
                [ngClass]="{
                  'tutorial-icon':
                    (tutorial$ | async) ===
                      TutorialTeacherSteps.COURSE_START_BTN && isTeacher
                }"
                exercise-ide-control-buttons
                [courseId]="courseId"
                [exercise]="exercise"
                [teacher]="isTeacher"
                [activeGroup]="activeGroup"
                (onStart)="start($event)"
              ></div>
            </ng-container>

            <ng-container *ngSwitchCase="isAnswerTypeFileUpload">
              <div
                [ngClass]="{
                  'tutorial-icon':
                    (tutorial$ | async) ===
                      TutorialTeacherSteps.COURSE_START_BTN && isTeacher
                }"
                exercise-file-upload-control-buttons
                [count]="teacherAnswersCount$ | async"
                [file]="uploadedFile$ | async"
                [answers]="studentAnswers$ | async"
                [courseId]="courseId"
                [groupId]="activeGroup"
                [isTeacher]="isTeacher"
                (sendSolutionEvent)="handleSendSolution()"
                (showModalEvent)="handleShowAnswerListModal()"
                (downloadEvent)="handleDownload($event)"
                (cancelUploadEvent)="handleCancelUpload($event)"
              ></div>
            </ng-container>

            <ng-container *ngSwitchCase="canShowQuizControlButtons">
              <div
                exercise-quiz-control-buttons
                [courseId]="courseId"
                [exercise]="exercise"
                [teacher]="isTeacher"
                [activeGroup]="activeGroup"
                (onStart)="start($event)"
                [ngClass]="{
                  'tutorial-icon':
                    (tutorial$ | async) ===
                      TutorialTeacherSteps.COURSE_START_BTN && isTeacher
                }"
              ></div>
            </ng-container>

            <ng-container *ngSwitchCase="canShowNoneControlButtons">
              <div
                [ngClass]="{
                  'tutorial-icon':
                    (tutorial$ | async) ===
                      TutorialTeacherSteps.COURSE_START_BTN && isTeacher
                }"
                exercise-none-control-buttons
                [exercise]="exercise"
                (onMarkAsAnswered)="start($event)"
              ></div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div
                [ngClass]="{
                  'tutorial-icon':
                    (tutorial$ | async) ===
                      TutorialTeacherSteps.COURSE_START_BTN && isTeacher
                }"
                exercise-control-buttons
                [courseId]="courseId"
                [exercise]="exercise"
                [teacher]="isTeacher"
                [activeGroup]="activeGroup"
                (onStart)="start($event)"
                (onStop)="stop($event)"
              ></div>

              <div
                *ngIf="canShowNoneControlButtonsInStartedExercise"
                exercise-none-control-buttons
                [exercise]="exercise"
                (onMarkAsAnswered)="answer($event)"
              ></div>
            </ng-container>
          </div>
        </div>
      </div>
      <course-exercise-help
        [isTeacher]="isTeacher"
        [exercise]="exercise"
        [courseId]="courseId"
        [topicId]="topicId"
        [groupId]="isTeacher ? null : activeGroup"
        [emitters]="emitters"
        [ngClass]="{
          help: isTeacher
            ? exercise.available_help || exercise.available_video
            : exercise.has_help || exercise.has_video,
          'help-no-margin': isOnboarding
        }"
      />
    </div>
  </div>
</ng-container>

<ng-template #loaderSpinner>
  <loading-spinner></loading-spinner>
</ng-template>
